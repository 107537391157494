// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanLoadFn, CanMatchFn, Router } from '@angular/router';
import { Observable, iif } from 'rxjs';
import { concatMap, defaultIfEmpty, finalize, first, map, tap } from 'rxjs/operators';

import { IsMobileService } from 'src/app/is-mobile.service';
import { UserService } from 'src/app/user/user.service';
import { AuthService, USER_EXPLICIT_REAUTH } from './auth.service';

export const canActivate: CanActivateFn = (route, state) => _checkLogin(state.url);

export const canActivateChild: CanActivateChildFn = (childRoute, state) => _checkLogin(state.url);

export const canMatch: CanMatchFn = (route, segments) => {
  const redirectPath = segments.map(segment => segment.path).join('/');
  return _checkLogin(redirectPath);
};
// FIXME: We should be using `canMatch` but `canMatch` guard runs on every route navigation. Where as `canLoad` that
// we have been using before angular17 upgrade only runs once. We need to review our `canMatch` func declaration. ATM
// when it runs on every route navigation, it fires off observable emissions that was previously assumed to only fire
// once.
export const canLoad: CanLoadFn = (route, segments) => {
  const redirectPath = segments.map(segment => segment.path).join('/');
  return _checkLogin(redirectPath);
};

const _checkLogin = (redirectPath: string): Observable<boolean> => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const isMobileService = inject(IsMobileService);
  const router = inject(Router);
  return authService.isAuthenticated$.pipe(
    concatMap(() => authService.handleAuthCallback$()),
    concatMap(result => {
      if (result === USER_EXPLICIT_REAUTH) {
        // Explicitly log the user out, and it redirects to the login page. `logout$()` returns an observable that does not emit any values, just the complete notification. Here, we return false to prevent Angular from trying other Routes' path to match against. Previously, it continues to try and matches successfully against the `PageNotFound` component (which has the route path "**"), showing it briefly before redirecting to the login page.
        return authService.logout$().pipe(defaultIfEmpty(false));
      }

      return iif(
        () => result.authenticated,
        userService.currentUser$.pipe(
          first(),
          map(user => !!user.termsOfUseAccepted),
          tap((termsOfUseAccepted: boolean) => {
            if (termsOfUseAccepted) {
              return;
            }
            if (location.hostname.includes('demo.fairsupply.io') || location.hostname.includes('localhost')) {
              userService.updateTermsOfUseAgreement$().pipe(first()).subscribe();
            } else {
              // They haven't accepted terms of use agreement. Show them the agreement to accept.
              $('#termsOfUseModal').modal('show');

              // Keep track of the redirect path
              sessionStorage.setItem('tou-redirect-path', redirectPath);
            }
          }),
          tap(() => isMobileService.detectMobile()),
          // Redirect if required
          finalize(() => (result.targetUrl ? router.navigate([result.targetUrl]) : null)),
        ),
        // Not logged in. Attempt to login.
        authService.login$(redirectPath).pipe(map(() => false)),
      );
    }),
  );
};
